import React, { FC } from "react";
import { pickLogo } from "../../../utils/pickFunctions";
import { useThemeContext } from "../../../context/theme";

interface Props {
  name: string;
  lastName: string;
  position: string;
}

export const NameTable: FC<Props> = ({ name, lastName, position }) => {
  const { theme } = useThemeContext();
  return (
    <>
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            <td width={500}>
              <p
                style={{
                  color: "#000000",
                  fontSize: "25px",
                  fontWeight: "500",
                  lineHeight: "27px",
                  letterSpacing: "0.74px",
                  margin: "0",
                }}
              >
                {name} {lastName}
              </p>
              <p
                style={{
                  color: "#000000",
                  fontSize: "14px",
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                  margin: "0 0 8px 0",
                }}
              >
                {position}
              </p>
            </td>
            <td>
              <img
                src={`${pickLogo(theme)}`}
                alt="logo"
                width={45}
                height={45}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {/*<table >*/}
      {/*  <tbody>*/}
      {/*    <tr>*/}
      {/*      <td>*/}
      {/*        <p*/}
      {/*          style={{*/}
      {/*            color: "#000000",*/}
      {/*            fontSize: "23px",*/}
      {/*            fontWeight: "500",*/}
      {/*            lineHeight: "25px",*/}
      {/*            letterSpacing: "0.74px",*/}
      {/*            margin: "0px 0 4px 0",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {lastName}*/}
      {/*        </p>*/}
      {/*      </td>*/}
      {/*    </tr>*/}
      {/*  </tbody>*/}
      {/*</table>*/}
    </>
  );
};
