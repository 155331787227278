import React from "react";
import ReactMarkdown from "react-markdown";
import { useAppContext } from "../../../context";
import styled from "styled-components";

export const StyledMarkdown = styled(ReactMarkdown)`
  a {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: justify;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: justify;
    margin: 0;
    color: #000;
  }
`;

export const CompanyInfo = () => {
  const { markdownData } = useAppContext();
  return (
    <table width={550}>
      <tbody>
        {markdownData && (
          <>
            <tr>
              <td>
                <StyledMarkdown>{markdownData}</StyledMarkdown>
              </td>
            </tr>
            <tr>
              <td>
                <hr
                  style={{  opacity: "0.4", margin: "10px 0" }}
                />
              </td>
            </tr>
          </>
        )}

        <tr>
          <td>
            <p
              style={{
                fontSize: "8px",
                fontWeight: "400",
                lineHeight: "14px",
                textAlign: "justify",
                color: "#939396",
                margin: "0",
              }}
            >
              ITEO S.A. with its registered seat in Katowice (40-527) at 6
              Gawronów street, entered into the Entrepreneurs’ Registry of
              National Court Register kept by the District Court Katowice-Wschód
              in Katowice VIII Commercial Department of KRS, under No. KRS
              0000967984, Tax Identification (NIP) No. 634-277-38-37, REGON
              statistical number 241858160, Nominal capital: PLN 105.000,00
              fully paid.
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p
              style={{
                fontSize: "8px",
                fontWeight: "400",
                lineHeight: "14px",
                textAlign: "justify",
                color: "#939396",
              }}
            >
              CONFIDENTIALITY. This email and any attachments are confidential
              and may also be privileged. If received in error, please do not
              disclose the contents to anyone, but notify the sender by return
              email and delete this email and any attachments from your system.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
