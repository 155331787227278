import React, { FC } from "react";
import { useThemeContext } from "../../../context/theme";
import { useAppContext } from "../../../context";
import { SocialMediaTable } from "./SocialMediaTable";

interface Props {
  email: string;
  number: string;
}

export const InfoTable: FC<Props> = () => {
  const { theme } = useThemeContext();
  const { user } = useAppContext();
  return (
    <table cellPadding="0" cellSpacing="0">
      <tbody>
        {user.number && (
          <tr>
            <td>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.28px",
                  margin: "0",
                  whiteSpace: "nowrap",
                }}
              >
                +48 {user.number}
              </p>
            </td>
          </tr>
        )}
        {theme === "secondary" && (
          <tr>
            <td >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0.28px",
                  margin: "0",
                  whiteSpace: "nowrap",
                }}
              >
                <a
                  href="https://hellobot.pl/"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  www.hellobot.pl
                </a>
              </p>
            </td>
          </tr>
        )}
        <tr>
          <td width={340}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.28px",
                margin: "0",
                whiteSpace: "nowrap",
              }}
            >
              <a
                href="https://iteo.com"
                style={{ textDecoration: "none", color: "#000" }}
              >
                www.iteo.com
              </a>
            </p>
            <table cellPadding="0" cellSpacing="0" />
          </td>
          <td>
            <SocialMediaTable />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
